// import Vue from 'vue'
import { Component, Watch } from "vue-property-decorator";
import baseUserContextPage from "./baseUserContextPage";
import axios, { AxiosPromise } from "axios";
import { productModel, productType } from "@/models/productModel";
import { mediaType, mediaModel } from "@/models/mediaModel";
import { sisasListiniModel } from "@/models/sisasPimListiniModel";
import { iProductOption } from "@/models/optionModel";
import { categoryModel } from "@/models/categoryModel";
import domainService from "@/services/domainService";
import { facetModel } from "@/models/facetModel";
import eventHub from "@/eventHub";
var Qs = require("qs");

class variantDef {
  constructor() {
    this.code = "";
    this.label = "";
  }
  code: string;
  label: string;
}
@Component({})
export default class productPage extends baseUserContextPage {
  isLoading: boolean = false;
  isLoadingMaster: boolean = false;
  isLoadingListini: boolean = false;
  // isLoadingRelations: boolean = false;
  addToCartIsOpen: boolean = false;

  showCustomerModal: boolean = false;
  showAddToCartModal: boolean = false;
  item: productModel = new productModel(); //{ options: [], sku: null, familyOptions: {}, name: {} };
  master: productModel = new productModel();
  categories: categoryModel[] = [];
  isLoadingCategoryFacets: boolean = false;
  categoryFacets: facetModel[] = [];
  // relations: productRelation[] = [];
  // @Watch("$route")
  // onRouteChange(to, from) {
  //   this.fetch();
  // }
  //  get urlFilters(): any {
  //   var instance = this;
  //   var params = instance.$route.query;
  //   debugger;
  //   return params.filters != undefined && params.filters != null ? Qs.parse(params.filters) : {};
  //  }
  get showScrollToTop(): boolean {
    return this.bodyScrollTop && this.bodyScrollTop > 300;
  }
  bodyScrollTop: number = null;
  onScroll(event) {
    var scrollTop =
      window.pageYOffset ||
      ((document.documentElement ||
        document.body.parentNode ||
        document.body) as any).scrollTop;
    this.bodyScrollTop = scrollTop || 0;
  }
  checkCategoryId() {
    var instance = this;
    var categoryId = null;
    if (instance.item == null) return null;

    if (instance.$route.params && instance.$route.params.categoryId)
      categoryId = instance.$route.params.categoryId as any;

    categoryId = Number.parseInt(categoryId);
    if (Number.isNaN(categoryId)) categoryId = null;

    if (categoryId != instance.categoryId && instance.categoryId != null) {
      // debugger;
      let currentParams = instance.$router.currentRoute.params;

      let queryParams = instance.$router.currentRoute.query || {};

      // var q = {
      //   categoryId: instance.categoryId,
      //   filters: queryParams.filters
      // };
      // instance.$router.replace({
      //   name: instance.$router.currentRoute.name,
      //   params: currentParams,
      //   query: q as any
      // });

      var p = {
        categoryId: instance.categoryId,
        id: currentParams.id
      };
      instance.$router.replace({
        name: instance.$router.currentRoute.name,
        params: p as any,
        query: queryParams
      });

    }
  }

  onCategorySelected(c: categoryModel) {
    var instance = this;
    // instance.$router.push({
    //   name: "catalog",
    //   query: {
    //     categoryId: c == null ? null : c.id.toString()
    //   }
    // });
    if (c == null) {
      instance.$router.push({ path: `/categories` });
    }
    else if (c.subCategories == null || c.subCategories.length == 0) {
      instance.$router.push({
        name: "catalog",
        params: {
          categoryId: c == null ? null : c.id.toString()
        }
      });
    }
    else if (c.subCategories.every(s => s.subCategories == null || s.subCategories.length == 0)) {
      instance.$router.push({
        name: "catalog",
        params: {
          categoryId: c == null ? null : c.id.toString()
        }
      });
    }
    else {
      instance.$router.push({ path: `/categories/${c.id}` });
    }
  }
  get categoryId(): number {
    var instance = this;
    var categoryId = null;
    if (instance.item == null) return null;

    if (instance.$route.params && instance.$route.params.categoryId)
      categoryId = instance.$route.params.categoryId as any;

    categoryId = Number.parseInt(categoryId);
    if (Number.isNaN(categoryId)) categoryId = null;

    if (
      categoryId != null &&
      instance.item.categoryIds.indexOf(categoryId) != -1
    )
      return categoryId;

    return instance.item.categoriesIdDefault != null
      ? instance.item.categoriesIdDefault
      : instance.item.categoryIds.length > 0
        ? instance.item.categoryIds[0]
        : null;
  }
  get mainSelectedCategory(): categoryModel {
    var instance = this;
    if (instance.categories == null) return new categoryModel();
    var c = instance.categories.find(x => instance.isCategorySelected(x));
    return c == null ? new categoryModel() : c;
  }
  get mainSelectedCategoryId(): number {
    var instance = this;
    return instance.mainSelectedCategory.id;
  }
  @Watch("mainSelectedCategoryId")
  onMainSelectedCategoryId(newId, oldId) {
    var instance = this;
    // instance.fetchCategoryFacets();
  }
  get subCategories(): categoryModel[] {
    var instance = this;
    var c = instance.mainSelectedCategory;
    return c != null && c.subCategories != null ? c.subCategories : [];
  }
  getFacetCategory(category: categoryModel): number {
    var instace = this;
    if (instace.categoryFacets == null) return null;
    var f = instace.categoryFacets.find(x => (x.code = "CategoryId"));
    if (f == null) return null;
    var fc = f.values[category.id];
    return fc == null ? 0 : fc;
  }
  getCategoryPropery(category: categoryModel, property: string) {
    if (category == null || category.locales == null) return "";
    var l = category.locales["it-IT"];
    return l == null ? "" : l[property];
  }
  isCategorySelected(c: categoryModel): boolean {
    var instace = this;
    return (
      c != null &&
      (c.id == instace.categoryId ||
        this.isAnyChildrenCategorySelected(c.subCategories))
    );
  }

  isAnyChildrenCategorySelected(categories: categoryModel[]): boolean {
    var instance = this;
    if (instance.categoryId == null) return false;
    if (categories == null || categories.length == 0) return false;
    else {
      return (
        categories.find(
          x =>
            x.id == instance.categoryId ||
            this.isAnyChildrenCategorySelected(x.subCategories)
        ) != null
      );
    }
  }

  onCustomerSelected(customer: any) {
    // this.fetchListini();
  }

  get id(): number {
    return this.$route && this.$route.params && this.$route.params.id
      ? Number.parseInt(this.$route.params.id)
      : null;
  }
  @Watch("$route")
  onRouteChange(to, from) {
    var instance = this;
    if (to.params.id != from.params.id) instance.fetch();
  }

  fetch(): void {
    var instance = this;
    instance.isLoading = true;
    instance.master = new productModel();

    if (instance.id != null) {
      // axios
      //   .get<any>(`/product/info`, {
      //     params: {
      //       productId: instance.id,
      //       customerId: instance.getCurrentCustomerId
      //     }
      //   })
      //   .then(res => {
      //     instance.item = res.data ? res.data : new productModel();
      //     instance.isLoading = false;
      //     instance.checkCategoryId();
      //     instance.fetchMaster();
      //   })
      //   .catch(error => {
      //     console.error(error);
      //     instance.isLoading = false;
      //   });
      axios
        .get<productModel>("/product/masterorproduct", {
          params: {
            productId: instance.id,
            loadListini: true,
            customerId: instance.getCurrentCustomerId
          }
        })
        .then(res => {
          if (res) {
            let prod = res.data || new productModel();
            if (instance.id != prod.id) {
              var sku = (prod.skus || new Array<productModel>()).find(
                x => x.id == instance.id
              );
              let queryParams = instance.$router.currentRoute.query || {};
              let filters = queryParams.filters || {};
              let hasFilters = false;
              // debugger;
              if (sku != null && sku.variantsValues != null) {
                for (const [key, value] of Object.entries(sku.variantsValues)) {
                  if (filters[key] == null) filters[key] = [];
                  filters[key].push(value);
                  hasFilters = true;
                }
              }
              var f = hasFilters ? Qs.stringify(filters) : undefined;
              let q = {
                categoryId:
                  queryParams.categoryId == null
                    ? undefined
                    : queryParams.categoryId,
                filters: f == null || f == "" ? undefined : f
              };
              instance.$router.replace({
                name: instance.$router.currentRoute.name,
                params: { id: prod.id.toString() },
                query: q
              });
              // instance.$router.replace({
              //   path: `/product/${prod.id}?categoryId=${instance.categoryId}`
              // });
            }
            instance.item = prod;
            instance.checkCategoryId();
          }
          instance.isLoading = false;
        })
        .catch(error => {
          console.error(error);
          instance.isLoading = false;
        });
      // instance.fetchRelations();

    }
  }
  // fetchRelations() {
  //   var instance = this;
  //   instance.isLoadingRelations = true;
  //   axios
  //     .get<productRelation[]>("/product/relations", {
  //       params: {
  //         productId: instance.id,
  //         loadListini: true,
  //         customerId: instance.getCurrentCustomerId
  //       }
  //     }).then(res => {
  //       instance.relations = res.data || [];
  //       instance.isLoadingRelations = false;
  //     }).catch(err => instance.isLoadingRelations = false);
  // }
  // fetchMaster(): void {
  //   var instance = this;
  //   instance.master = new productModel();
  //   if (instance.item == null) {
  //     return;
  //   }
  //   if (instance.item.type == productType.Configurable) {
  //     instance.master = Object.assign({}, instance.item);
  //     instance.fetchListini();
  //     instance.isLoadingMaster = false;
  //   } else if (instance.item.masterId != null) {
  //     instance.isLoadingMaster = true;
  //     axios
  //       .get<any>(`/product/info`, {
  //         params: {
  //           productId: instance.item.masterId,
  //           customerId: instance.getCurrentCustomerId
  //         }
  //       })
  //       .then(res => {
  //         instance.master = res.data ? res.data : new productModel();
  //         instance.fetchListini();
  //         instance.isLoadingMaster = false;
  //       })
  //       .catch(error => {
  //         console.error(error);
  //         instance.isLoadingMaster = false;
  //       });
  //   } else {
  //     instance.fetchListini();
  //   }
  // }
  get skus(): string[] {
    var instance = this;
    if (instance.item == null) return [];

    var skus = [];
    if (instance.item.skus != null && instance.item.skus.length > 0) {
      instance.item.skus.forEach(p => {
        skus.push(p.sku);
        if (p.skus != null)
          p.skus.forEach(c => {
            skus.push(c.sku);
          });
      });
    } else if (
      instance.master != null &&
      instance.master.skus != null &&
      instance.master.skus.length > 0
    ) {
      instance.master.skus.forEach(p => {
        skus.push(p.sku);
        if (p.skus != null)
          p.skus.forEach(c => {
            skus.push(c.sku);
          });
      });
    } else {
      skus.push(instance.item.sku);
    }
    return skus;
  }
  //  fetchListino(): void {
  //   var instance = this;

  //   if (instance.skus != null && instance.skus.length > 0) {
  //    instance.isLoadingListini = true;
  //    axios
  //     .post<sisasPimListiniModel[]>("/product/listini", {
  //      customerId: this.getCurrentCustomerId,
  //      skus: instance.skus
  //     })
  //     .then(res => {
  //      if (res.data) {
  //       // instance.list.items.forEach(p => {
  //       //   p.listini = res.data.filter(x => x.articolo == p.sku);
  //       //   if (p.skus != null)
  //       //     p.skus.forEach(c => {
  //       //       c.listini = res.data.filter(x => x.articolo == c.sku);
  //       //     });
  //       // });
  //       instance.item.listini = res.data.filter(x => x.articolo == instance.item.sku);
  //      }
  //      instance.isLoadingListini = false;
  //     })
  //     .catch(error => {
  //      console.error(error);
  //      instance.isLoadingListini = false;
  //     });
  //   }
  //  }

  fetchListini(): void {
    var instance = this;
    instance.isLoadingListini = true;
    axios
      .post<sisasListiniModel[]>("/product/listini", {
        customerId: this.getCurrentCustomerId,
        skus: instance.skus
      })
      .then(res => {
        if (res.data) {
          instance.item.listini = res.data.filter(
            x => x.articolo == instance.item.sku
          );
          if (instance.master != null && instance.master.skus != null) {
            instance.master.skus.forEach(p => {
              p.listini = res.data.filter(x => x.articolo == p.sku);
              if (p.skus != null)
                p.skus.forEach(c => {
                  c.listini = res.data.filter(x => x.articolo == c.sku);
                });
            });
          }
        }
        instance.isLoadingListini = false;
      })
      .catch(error => {
        console.error(error);
        instance.isLoadingListini = false;
      });
  }
  // fetchCategoryFacets(): void {
  //   var instance = this;
  //   instance.isLoadingCategoryFacets = true;
  //   if (this.categoryId == null) {
  //     instance.categoryFacets = [];
  //     instance.isLoadingCategoryFacets = false;
  //   } else {
  //     axios
  //       .get<facetModel[]>(`/product/categoryFacets/${this.categoryId}`)
  //       .then(res => {
  //         instance.categoryFacets = res.data ? res.data : [];
  //         instance.isLoadingCategoryFacets = false;
  //       })
  //       .catch(error => {
  //         console.error(error);
  //         instance.isLoadingCategoryFacets = false;
  //       });
  //   }
  // }
  // onConfiguredClick(product: productModel) {
  //   var instance = this;
  //   if (product != null && product.id != this.item.id) {
  //     instance.item = product;
  //     this.$router.replace({
  //       path: `/product/${product.id}?categoryId=${instance.categoryId}`
  //     });
  //   }
  // }
  beforeMount() {
    domainService
      .getCategories()
      .then(res => (this.categories = res == null ? [] : res));
    eventHub.$on(eventHub.events.CURRENT_CUSTOMER_CHANGED, this.fetchCustomerChanged);
  }
  mounted() {
    var instance = this;

    instance.fetch();

    window.addEventListener("scroll", instance.onScroll);
    instance.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered
      instance.onScroll(null);
    });
  }
  beforeDestroy() {
    var instance = this;
    eventHub.$off(
      eventHub.events.CURRENT_CUSTOMER_CHANGED,
      instance.fetchCustomerChanged
    );

    window.removeEventListener("scroll", this.onScroll);
  }

  fetchCustomerChanged() {
    var instance = this;
    instance.fetchListini();
    // instance.fetchRelations();
  }
}
